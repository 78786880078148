import React from "react";
import ServiceNewspicksUser from "components/News/ServiceNewspicksUser";
import ServiceLazyLoad from "components/News/ServiceLazyLoad";
import NotFound from "contents/NotFound";
import { useQueries } from "libs/utils";

type Props = {
  userid: number;
  username: string;
};

export default function NewspicksUserNews({ userid, username }: Props) {
  const [{ q: query, p: page }] = useQueries(["q", "p"]);
  const title = `NewsPicks user - ${username}`;

  if (!userid || !username) return <NotFound />;

  return (
    <ServiceLazyLoad
      userid={userid}
      username={username}
      title={title}
      component={ServiceNewspicksUser}
      query={query}
      page={parseInt(page || "0")}
      className="bg-background"
    />
  );
}
