import React from "react";

import {
  Article,
  NewspicksComment,
  DEFAULT_LENGTH,
  INIT_COMMENT_LENGTH,
} from "./types";
import NPComment from "./NPComment";
import ServiceNewspicksComments from "./ServiceNewspicksComments";
import ExternalArticleSummary from "./ExternalArticleSummary";
import ArticlesListLoading from "./ArticlesListLoading";
import AlertError from "components/Alerts/AlertError";
import AlertInfo from "components/Alerts/AlertInfo";
import Link from "components/Common/Link";
import ServiceChart from "components/Charts/ServiceChart";
import { useSWRFetch } from "libs/api";

type Props = {
  userid: string;
  username: string;
  page?: number;
  length?: number;
  query?: string;
  className?: string;
};
export default function ServiceNewspicksUser({
  userid,
  username,
  page = 0,
  length = DEFAULT_LENGTH,
  query = "",
  className,
}: Props) {
  if (page < 0) page = 0;
  const start = page * length;
  const q =
    query && query.length > 1 ? "&search[value]=" + encodeURI(query) : "";
  const url = `${process.env.REACT_APP_API_PATH_NEWSPICKS_USER}?user=${userid}&start=${start}&length=${length}${q}`;
  const { data: news, isLoading, error, noData } = useSWRFetch(url);

  if (error)
    return (
      <div className="flex flex-wrap">
        <div className="w-full p-3">
          <AlertError message={`Could not load user ${username} comments`} />
        </div>
      </div>
    );
  if (isLoading) return <ArticlesListLoading />;

  if (noData)
    return (
      <div className="flex flex-wrap">
        <div className="w-full p-3">
          <AlertInfo message={`There is no comments from ${username}`} />
        </div>
      </div>
    );

  return (
    <div className={className}>
      <section className="py-6 my-4 mt-14 w-screen md:w-full">
        <div className="w-full mx-auto px-4">
          <div className="min-w-0 break-words bg-white w-full p-3 mb-6 shadow-xl rounded-lg">
            <div className="w-full px-6">
              <div className="flex justify-center mx-auto">
                <div className="flex justify-center">
                  <div className="relative">
                    <img
                      alt=""
                      src={`https://newspicks.com/users/${userid}/cover?width=100&height=100`}
                      className="shadow-xl rounded-full h-auto align-middle border-none absolute -ml-12 -mt-16 max-w-100-px"
                    />
                  </div>
                </div>
              </div>
              <div className="text-center mt-12 text-gray-700">
                <h3 className="text-2xl leading-normal mb-2">
                  {news[0].username}
                </h3>
                <div>{news[0].userjob}</div>
              </div>
            </div>
            <div className="w-full flex p-6 text-gray-600">
              <div className="w-full md:w-1/2">
                <ServiceChart
                  id="comments-count"
                  chartId="npuser-comments-count"
                  showName={false}
                  subtitle="日別コメント数"
                  type="line"
                  width="90%"
                  height="250px"
                  params={{
                    user: userid,
                  }}
                  className="m-3"
                />
              </div>
              <div className="w-full md:w-1/2">
                <ServiceChart
                  id="comments-count"
                  chartId="npuser-liked-count"
                  showName={false}
                  subtitle="日別いいね数"
                  type="line"
                  width="90%"
                  height="250px"
                  params={{
                    user: userid,
                  }}
                  className="m-3"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {news.map((article: Article & NewspicksComment, i: number) => (
        <div key={i} className="w-screen md:w-full">
          <ExternalArticleSummary article={article} />
          <ServiceNewspicksComments
            link={article.link}
            show={INIT_COMMENT_LENGTH}
          />
        </div>
      ))}
    </div>
  );
}
