import React from "react";
import { Router, WindowLocation } from "@reach/router";
import Route from "components/Routes/Route";
import NotFound from "contents/NotFound";
import NewspicksUserNews from "components/News/NewspicksUserNews";
import NewsLayout from "layouts/NewsLayout";

export default function Page() {
  return (
    <NewsLayout backButton={true}>
      <Router basepath="/npuser">
        <Route component={NotFound} default />
        <Route
          path="/:userid/:username"
          component={NewspicksUserNews}
          className="bg-background"
        />
      </Router>
    </NewsLayout>
  );
}
